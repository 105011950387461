import React, { useState, useEffect, useContext } from "react";
import { Button, Form, Row, Spinner } from "react-bootstrap";
import { fullValidation } from "../helper/WebResourceValidators";
import {
  APIWebVisualization,
  APIFileVisualization,
} from "../helper/APIFunctions";
import { UserInfo, VisualCache } from "../helper/Context";
import UploadResultDisplay from "./UploadResultDisplay";
import {
  canUploadVideo,
  getMaxUploadSizeBytes,
  getMaxUploadSizeMB,
  getVisualLifetimeHrs,
} from "../helper/BusinessLogic";
import JSZip from "jszip";
import { shallowCopy, prepBrandProfileList } from "../helper/Utils.js";
const TitleDesc = (props) => {
  return (
    <>
      <Form.Group controlId={props.idPrefix + "-title"} className="mb-3">
        <Row>
          <Form.Label className="col-sm-3">Title</Form.Label>
          <Form.Control
            type="text"
            value={props.title}
            placeholder="Optional Title for Visual"
            dir="auto"
            onChange={(event) => {
              props.setTitle(event.target.value);
            }}
            disabled={props.disabled}
          />
        </Row>
      </Form.Group>
      <Form.Group controlId={props.idPrefix + "-description"} className="mb-3">
        <Row>
          <Form.Label className="col-sm-3">Description</Form.Label>
          <Form.Control
            as="textarea"
            value={props.desc}
            placeholder="Optional Description for Visual"
            dir="auto"
            onChange={(event) => {
              props.setDesc(event.target.value);
            }}
            disabled={props.disabled}
          />
        </Row>
      </Form.Group>
      {props.brandProfileOptions && props.brandProfileOptions.length >= 2 ? (
        <Form.Group
          controlId={props.idPrefix + "-branding"}
          className="mb-3"
        >
          <Row>
            <Form.Label className="col-sm-3">Branding Profile: </Form.Label>
            <Form.Select
              value={props.selectedBrandProfile}
              onChange={(event) => {
                props.setBrandProfile(event.target.value);
              }}
              disabled={props.disabled}
              className="col-sm-8 w-75"
            >
              {props.brandProfileOptions.map((e) => (
                <option key={e.id} value={e.id}>
                  {e.profile_name} {e.is_default ? " (default)" : null}
                </option>
              ))}
            </Form.Select>
          </Row>
        </Form.Group>
      ) : null}
    </>
  );
};

const setWebFieldsetDisabled = (isDisabled) => {
  const t = document.getElementById("snorkle-web-fieldset")
  if (t) {
    t.disabled = isDisabled;
  }
}
export const WebUploadForm = (props) => {
  const [title, setTitle] = useState("");
  const { userInfo } = useContext(UserInfo);
  const [description, setDescription] = useState("");
  const [webResource, setWebResource] = useState("");
  const [validatedSource, setValidatedSource] = useState({}); // empty object for not yet validated, null for validation failed
  const [uploadingCount, setUploadingCount] = useState(0);
  const [uploadResult, setUploadResult] = useState([]);
  const { visCache, setVisCache } = useContext(VisualCache);
  const [webResourceTouched, setWebResourceTouched] = useState(false);
  const brandProfileOptions = prepBrandProfileList(userInfo.branding_profiles);
  const [brandProfileId, setBrandProfileId] = useState(
    (brandProfileOptions[0] || {}).id
  );
  const webUploadSuccess = (body) => {
    setUploadResult(ur => [...ur, {
      success: true,
      payload: body,
    }]);
    const tempVisCache = shallowCopy(visCache);
    tempVisCache[body.id] = body;
    setVisCache(tempVisCache);
    setUploadingCount(i => i - 1);
  };
  const webUploadFailure = (body) => {
    let err_msg = "";
    if (body.error) {
      err_msg = body.error;
    } else {
      err_msg = body.toString();
    }
    setUploadResult(ur => [...ur, {
      success: false,
      payload: err_msg,
    }]);
    setUploadingCount(i => i - 1);
  };
  useEffect(() => {
    if (props.breadcrumbs && props.baseCrumbs && props.setBreadcrumbs) {
      const len = props.breadcrumbs.length;
      if (
        len !== props.baseCrumbs.length + 1 ||
        props.breadcrumbs[len - 1].url !==
        props.baseCrumbs[props.baseCrumbs.length - 1].url + "/web"
      ) {
        let newBC = [...props.baseCrumbs];
        newBC.push({
          name: "Web Source",
          url: props.baseCrumbs[props.baseCrumbs.length - 1].url + "/web",
        });
        props.setBreadcrumbs(newBC);
      }
    }
  });
  return (
    <>
      {uploadResult && uploadResult.length && !uploadingCount ? (
        <UploadResultDisplay result={uploadResult[uploadResult.length - 1]} isOrg={props.isOrg} />
      ) : null}
      {uploadingCount ? (
        <div className="text-center w-100" id="upload-web-processing">
          <h5>Processing {uploadingCount > 1 ? `${uploadingCount} new visuals` : "1 new visual"}...</h5>
          <p>This may take a little bit. Feel free to continue using the site.</p>
          <Spinner
            animation="border"
            role="uploading"
            className="text-secondary"
          >
            <span className="visually-hidden">Uploading...</span>
          </Spinner>
        </div>
      ) : null}

      <Form
        className="m-4"
        id="web-resource-form"
        onSubmit={(event) => {
          setWebFieldsetDisabled(true);
          setTimeout(() => setWebFieldsetDisabled(false), 1000);
          if (validatedSource.type == "simple_image") {
            APIFileVisualization(
              validatedSource.id,
              title,
              description,
              webUploadSuccess,
              webUploadFailure,
              false,
              { isSimple: true, branding_profile: brandProfileId }
            );
          } else {
            APIWebVisualization(
              validatedSource.id,
              validatedSource.type,
              title,
              description,
              webUploadSuccess,
              webUploadFailure,
              props.isOrg,
              { hash: validatedSource.hash, branding_profile: brandProfileId }
            );
          }
          setUploadingCount(i => i + 1);
          event.preventDefault();
        }}
      >
        <fieldset id="snorkle-web-fieldset">
          <Form.Group controlId="upload-web-resource" className="mb-3">
            <Row>
              <Form.Label className="col-sm-3">Web Resource</Form.Label>
              <Form.Control
                type="text"
                value={webResource}
                isInvalid={validatedSource === null && webResourceTouched}
                isValid={validatedSource && validatedSource.id}
                onChange={(event) => {
                  const value = event.target.value;
                  setValidatedSource({});
                  setWebResource(value);
                  fullValidation(value, !!props.isLoggedIn)
                    .then((res) => {
                      setValidatedSource(res);
                      if (res.type === "snorkle") {
                        setTitle("");
                        setDescription("");
                      }
                    })
                    .catch((err) => {
                      setValidatedSource(null);
                    });
                }}
                onBlur={(event) => {
                  setWebResourceTouched(true);
                }}
                placeholder="URL for Web Resource"
              />
              <Form.Control.Feedback type="invalid">
                Invalid web resource
              </Form.Control.Feedback>
            </Row>
            <Form.Text>
              Accepts URLs for Sketchfab models
              {!!props.isLoggedIn
                ? ", PDB entries, and other Snorkle visuals"
                : " and PDB entries"}
              .
            </Form.Text>
          </Form.Group>

          {props.isLoggedIn ? (
            <TitleDesc
              title={title}
              setTitle={setTitle}
              desc={description}
              setDesc={setDescription}
              idPrefix="upload-web"
              setBrandProfile={setBrandProfileId}
              brandProfileOptions={
                (validatedSource || {}).type == "snorkle" &&
                  !visCache[validatedSource.id]
                  ? []
                  : brandProfileOptions
              }
              disabled={validatedSource && validatedSource.type === "snorkle" && !visCache[validatedSource.id]}
            />
          ) : null}
          <Button
            type="submit"
            disabled={!validatedSource || !validatedSource.id}
            id="upload-web-submit-btn"
          >
            Create
          </Button>
          {!props.isLoggedIn ? (
            <p className="form-text">
              Visuals uploaded as a guest will only be accessible for 2 weeks.
            </p>
          ) : null}
        </fieldset>
      </Form>
    </>
  );
};

const setFileFieldsetDisabled = (isDisabled) => {
  const t = document.getElementById("snorkle-file-fieldset")
  if (t) {
    t.disabled = isDisabled;
  }
}

export const FileUploadForm = (props) => {
  const alwaysAcceptedExtensions = [
    ".png",
    ".jpg",
    ".jpeg",
    ".tif",
    ".tiff",
    ".zip",
    ".dcm",
    ".DCM",
    ".czi",
    ".mtl",
    ".obj",
    ".stl",
    ".ply",
    ".dae",
    ".bin",
    ".gltf",
    ".glb",
  ];
  const videoExtensions = [
    ".mov",
    ".mp4",
    ".webm",
    ".mkv",
    ".flv",
    ".vob",
    ".ogv",
    ".ogg",
    ".qt",
    ".wmv",
    ".amv",
    ".mp4",
    ".m4v",
    ".mpg",
    ".mp2",
    ".mpeg",
    ".mpe",
  ];
  const modelExtensions = [".obj", ".stl", ".ply", ".dae", ".gltf", ".glb"];
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [localFiles, setLocalFiles] = useState(null);
  const [uploadingCount, setUploadingCount] = useState(0);
  const [uploadResult, setUploadResult] = useState([]);
  const [fileError, setFileError] = useState(null);
  const [filesValid, setFilesValid] = useState(false);
  const [simpleSwitch, setSimpleSwitch] = useState(true);
  const [isVideo, setIsVideo] = useState(false);
  const { visCache, setVisCache } = useContext(VisualCache);
  const { userInfo, setUserInfo } = useContext(UserInfo);
  const brandProfileOptions = prepBrandProfileList(userInfo.branding_profiles);
  const [brandProfileId, setBrandProfileId] = useState(
    (brandProfileOptions[0] || {}).id
  );

  const [acceptedExtensions, setAcceptedExtensions] = useState(
    alwaysAcceptedExtensions
  );
  const canBeSimple = () => {
    if (!localFiles || !localFiles[0] || localFiles.length > 1) {
      return false;
    }
    const arr = localFiles[0].name.split(".");
    if (arr.length == 1) {
      return false;
    }
    const extension = "." + arr[arr.length - 1];
    if ([".png", ".jpg", ".jpeg"].some((e) => e == extension)) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (canUploadVideo(userInfo, props.isOrg)) {
      setAcceptedExtensions(alwaysAcceptedExtensions.concat(videoExtensions));
    } else {
      setAcceptedExtensions(alwaysAcceptedExtensions);
    }
  }, [userInfo]);
  useEffect(() => {
    if (!localFiles || !localFiles[0]) {
      setFilesValid(false);
      return;
    }
    const arr = localFiles[0].name.split(".");
    if (arr.length == 1) {
      setFileError("Unable to identify extension.");
      setFilesValid(false);
      return;
    }
    const extension = "." + arr[arr.length - 1];
    if (!acceptedExtensions.some((e) => e === extension.toLowerCase())) {
      setFileError("Invaild file extension.");
      setFilesValid(false);
      return;
    }
    if (extension === ".zip" && localFiles.length > 1) {
      setFileError("Cannot upload multiple .zip files");
      setFilesValid(false);
      return;
    } else if (
      (extension === ".tiff" || extension === ".tif") &&
      localFiles.length > 1
    ) {
      setFileError("Cannot upload multiple .tiff files");
      setFilesValid(false);
      return;
    } else if (extension === ".czi" && localFiles.length > 1) {
      setFileError("Cannot upload multiple .czi files");
      setFilesValid(false);
      return;
    }
    let currentSize = 0;
    const effectiveTier = userInfo ? userInfo.tier : "guest";
    const sizeLimit = getMaxUploadSizeBytes(effectiveTier);
    let mismatch = false;
    let isModel = false;
    for (let i = 0; i < localFiles.length; i++) {
      mismatch |= !localFiles[i].name.endsWith(extension);
      const arr = localFiles[i].name.split(".");
      const ext = "." + arr[1];
      isModel |= modelExtensions.some((e) => e == ext);
      currentSize += localFiles[i].size;
      if (currentSize > sizeLimit) {
        setFileError(
          `Max combined upload size is ${getMaxUploadSizeMB(effectiveTier)}MB`
        );
        setFilesValid(false);
        return;
      }
    }
    if (mismatch && !isModel) {
      setFileError("All files must have the same extension");
      setFilesValid(false);
      return;
    }
    setFileError(null);
    setFilesValid(true);
    setIsVideo(videoExtensions.some((e) => e == extension));
  }, [localFiles]);
  useEffect(() => {
    if (props.breadcrumbs && props.baseCrumbs && props.setBreadcrumbs) {
      const len = props.breadcrumbs.length;
      if (
        len !== props.baseCrumbs.length + 1 ||
        props.breadcrumbs[len - 1].url !==
        props.baseCrumbs[props.baseCrumbs.length - 1].url + "/image"
      ) {
        let newBC = [...props.baseCrumbs];
        newBC.push({
          name: "Image Source",
          url: props.baseCrumbs[props.baseCrumbs.length - 1].url + "/image",
        });
        props.setBreadcrumbs(newBC);
      }
    }
  });
  const fileUploadSuccess = (body) => {
    setUploadResult((ur) => {
      const t = [...ur, {
        success: true,
        payload: body,
      }];
      console.log(ur, t);
      return t;
    });
    setUploadingCount(i => i - 1);
    const tempVisCache = shallowCopy(visCache);
    tempVisCache[body.id] = body;
    setVisCache(tempVisCache);
  };
  const fileUploadFailure = (body) => {
    let err_msg = "";
    if (body.error) {
      err_msg = body.error;
    } else {
      err_msg = body.toString();
    }
    setUploadResult(ur => [...ur, {
      success: false,
      payload: err_msg,
    }]);
    setUploadingCount(i => i - 1);
  };
  return (
    <>
      {uploadResult && uploadResult.length && !uploadingCount ? (
        <UploadResultDisplay result={uploadResult[uploadResult.length - 1]} isOrg={props.isOrg} />
      ) : null}
      {uploadingCount ? (
        <div className="text-center w-100" id="upload-web-processing">
          <h5>Processing {uploadingCount > 1 ? `${uploadingCount} new visuals` : "1 new visual"}...</h5>
          <p>This may take a few minutes. Feel free to continue using the site.</p>
          <Spinner
            animation="border"
            role="uploading"
            className="text-secondary"
          >
            <span className="visually-hidden">Uploading...</span>
          </Spinner>
        </div>
      ) : null}
      <Form
        className="m-4"
        id="file-resource-from"
        onSubmit={(event) => {
          const arr = localFiles[0].name.split(".");
          const base = arr[0];
          setFileFieldsetDisabled(true);
          setTimeout(() => setFileFieldsetDisabled(false), 1000);
          if (localFiles.length > 1) {
            let zip = JSZip();
            for (let i = 0; i < localFiles.length; i++) {
              zip.file(localFiles[i].name, localFiles[i]);
            }
            zip
              .generateAsync({ type: "blob" })
              .then((blob) => {
                APIFileVisualization(
                  blob,
                  title,
                  description,
                  fileUploadSuccess,
                  fileUploadFailure,
                  props.isOrg,
                  { filename: `${base}.zip`, branding_profile: brandProfileId }
                );
              })
              .catch(() => {
                setUploadResult(ur => [...ur, {
                  success: false,
                  payload: "Unable to upload selected files.",
                }]);
                setUploadingCount(i => i - 1);
              });
          } else {
            APIFileVisualization(
              localFiles[0],
              title,
              description,
              fileUploadSuccess,
              fileUploadFailure,
              props.isOrg,
              {
                isSimple: simpleSwitch && canBeSimple(),
                branding_profile: brandProfileId,
              }
            );
          }
          setUploadingCount(i => i + 1);
          event.preventDefault();
        }}
      >
        <fieldset id="snorkle-file-fieldset">
          <Form.Group controlId="upload-local-file" className="mb-3">
            <Row>
              <Form.Label className="col-sm-3">{"Your File"}</Form.Label>
              <Form.Control
                type="file"
                onChange={(event) => {
                  setLocalFiles(event.target.files);
                }}
                isInvalid={localFiles && localFiles[0] && !filesValid}
                accept={acceptedExtensions.join(",")}
                multiple
              />
              <Form.Control.Feedback type="invalid">
                {fileError}
              </Form.Control.Feedback>
            </Row>
            <Form.Text>
              <b>For static images and volumes:</b> accepts, compressed or raw, .png, .jpg, .jpeg, .tif, .tiff, .czi, .dcm, .stl, .glb, .obj, .ply, zipped archives of .tif, .tiff, .dcm files; <b>for videos (premium feature):</b> accepts: .mov, .mp4, .webm, .mkv, .flv, .vob, .ogv, .ogg, .qt, .wmv, .amv, .mp4, .m4v, .mpg, .mp2, .mpeg, .mpe. Upload file size is limited
              to{" "}
              {getMaxUploadSizeMB(
                userInfo ? userInfo.tier : "guest",
                props.isOrg
              )}
              MB
              {props.isOrg ? (
                "."
              ) : (
                <> for {userInfo ? "your account tier" : "guests"}.</>
              )}
            </Form.Text>
          </Form.Group>
          {props.isLoggedIn ? (
            <TitleDesc
              title={title}
              setTitle={setTitle}
              desc={description}
              setDesc={setDescription}
              brandProfileOptions={!isVideo ? brandProfileOptions : []}
              setBrandProfile={setBrandProfileId}
              idPrefix="upload-local"
            />
          ) : null}
          {canBeSimple() ? (
            <Form.Group>
              <Form.Check
                type="switch"
                inline
                name="separate-channels"
                checked={!simpleSwitch}
                onChange={(e) => setSimpleSwitch(!e.target.checked)}
              />
              <Form.Label>Have Separate Channels (for small images)</Form.Label>
            </Form.Group>
          ) : null}
          <Button type="submit" disabled={!filesValid}>
            Upload
          </Button>
          {!props.isLoggedIn ? (
            <p className="form-text">
              Visuals uploaded as a guest will only be accessible for{" "}
              {getVisualLifetimeHrs("guest")} hours.
            </p>
          ) : null}
        </fieldset>
      </Form>
    </>
  );
};
