import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Image,
  Row,
  Col,
  Spinner,
  Button,
  Modal,
  Tooltip,
  Overlay,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faClipboard,
  faStar as faStarEmpty,
  faFileAudio as faAudioIcon,
} from "@fortawesome/free-regular-svg-icons";
import {
  faPencil,
  faShareFromSquare,
  faCamera,
  faStar as faStarFill,
  faGlobe as faPublicIcon,
  faLink as faUnlistedIcon,
  faLock as faPrivateIcon,
  faImage as imageIcon,
  faVideo,
  faDownload,
  faVideoSlash,
} from "@fortawesome/free-solid-svg-icons";
import {
  APIWebVisualization,
  APIEditVisualizations,
  APIGetDownloadableVersion,
} from "../helper/APIFunctions";
import DeleteModal from "./VisualModals/Delete";
import EditModal from "./VisualModals/Edit";
import ShareModal from "./VisualModals/Share";
import InitialViewModal from "./VisualModals/ChangeView";
import SnapshotModal from "./VisualModals/Snapshot";
import LandmarkModal from "./VisualModals/Landmarks";
import AudioModal from "./VisualModals/Audio";
import VideoRecordModal from "./VisualModals/VideoRecord";
import { Link } from "react-router-dom";
import { TagLookup, VisualCache, UserInfo } from "../helper/Context";
import {
  downloadHtml,
  shallowCopy,
  prepBrandProfileList,
} from "../helper/Utils";
import { canRecordVideo } from "../helper/BusinessLogic";

const getIcon = () => {
  return "/portal/snorkle_vis_thumbnails/snorkle_vis_icon.png";
};

const getIconTitle = () => {
  return "Snorkle Visual";
};

const prepareFilename = (fn) => {
  return (
    fn.replace(/ "/g, "_").replace(/\./g, "-").replace(/\//g, "-") + ".html"
  );
};

const VisCard = (props) => {
  const [copyNotice, setCopyNotice] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showSnapshotModal, setShowSnapshotModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showEditLandmarks, setShowEditLandmarks] = useState(false);
  const [showAudioModal, setShowAudioModal] = useState(false);
  const [showRecordModal, setShowRecordModal] = useState(false);
  const [displayTags, setDisplayTags] = useState([]);
  const [hasCamMic, setHasCamMic] = useState({ cam: false, mic: false });
  const [nocacheUrlSuffix, setNocacheUrlSuffix] = useState(
    `?now=${Date.now()}`
  );
  const { visCache, setVisCache } = useContext(VisualCache);
  const { userInfo } = useContext(UserInfo);
  const [downloadStatus, setDownloadStatus] = useState({
    state: null,
    msg: null,
  });
  const { tagLookup, setTagLookup } = useContext(TagLookup);
  const [titleOnly, setTitleOnly] = useState(false);
  const [showNewInitView, setShowInitView] = useState(
    props.showModal && props.canEdit && props.vis.can_save_view
  );
  const [showPreview, setShowPreview] = useState(
    props.showModal && !showNewInitView
  );
  useEffect(() => {
    setNocacheUrlSuffix(`?now=${Date.now()}`);
  }, [showPreview]);

  const ttTarget = useRef(null);
  const [showTT, setShowTT] = useState(false);
  useEffect(() => {
    setShowTT(downloadStatus.state == "error");
  }, [downloadStatus]);

  const [orgCopyState, setOrgCopyState] = useState({
    disable: false,
    spinner: false,
    message: "",
    textClass: "text-success",
  });
  const [favoriteCall, setFavoriteCall] = useState(false);

  useEffect(() => {
    const base = props.vis.tags || [];
    if (!base.length || base[0].slug) {
      setDisplayTags(base);
    } else {
      setDisplayTags(base.map((t) => tagLookup[t]).filter((t) => !!t));
    }
  }, [props.vis, tagLookup]);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(res => {
      const hasVideo = res.some(r => r.kind == "videoinput");
      const hasAudio = res.some(r => r.kind == "audioinput");
      setHasCamMic({ cam: hasVideo, mic: hasAudio });
    });
  }, [])

  const formatter = new Intl.DateTimeFormat(undefined, {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
  });

  let dateString = null;
  if (props.vis.created_timestamp) {
    const uploadDate = new Date(props.vis.created_timestamp * 1000);
    dateString = formatter.format(uploadDate);
  }

  let expireString = null;
  if (props.vis.expire_timestamp) {
    expireString = formatter.format(
      new Date(props.vis.expire_timestamp * 1000)
    );
  }

  const highlightText = (text, highlightPhrase) => {
    const lower = text.toLowerCase();
    const inds = [];
    let currInd = lower.indexOf(highlightPhrase);
    if (highlightPhrase && currInd >= 0) {
      inds[0] = currInd;
      currInd = lower.indexOf(
        highlightPhrase,
        inds[0] + highlightPhrase.length
      );
      while (currInd >= 0) {
        inds.push(currInd);
        currInd = lower.indexOf(
          highlightPhrase,
          inds[inds.length - 1] + highlightPhrase.length
        );
      }
      let output = [];
      output.push(text.substring(0, inds[0]));
      for (let i = 0; i < inds.length - 1; i++) {
        output.push(
          <mark className="p-0 bg-warning" key={i}>
            {text.substring(inds[i], inds[i] + highlightPhrase.length)}
          </mark>
        );
        output.push(
          text.substring(inds[i] + highlightPhrase.length, inds[i + 1])
        );
      }
      let i = inds.length - 1;
      output.push(
        <mark className="p-0  bg-warning" key={inds.length}>
          {text.substring(inds[i], inds[i] + highlightPhrase.length)}
        </mark>
      );
      output.push(text.substring(inds[i] + highlightPhrase.length));
      return output;
    } else {
      return text;
    }
  };

  let privacyIcon = null;
  let privacyTitle = "";
  if (!props.vis.privacy || props.vis.privacy == "unlisted") {
    privacyIcon = faUnlistedIcon;
    privacyTitle = "Unlisted";
  } else if (props.vis.privacy == "public") {
    privacyIcon = faPublicIcon;
    privacyTitle = "Public";
  } else if (props.vis.privacy == "private") {
    privacyIcon = faPrivateIcon;
    privacyTitle = "Password Protected";
  }
  return (
    <>
      <div className={titleOnly ? "d-none" : ""}>
        {props.canEdit ? (
          <EditModal
            vis={props.vis}
            show={showEditModal}
            onVisEdit={(
              localVis,
              newTitle,
              newDesc,
              newThumb,
              newHasShare,
              other
            ) => {
              setShowEditModal(false);
              props.onVisEdit(
                localVis,
                newTitle,
                newDesc,
                newThumb,
                newHasShare,
                other
              );
            }}
            isVideo={!props.vis.qr_image}
            onCancel={() => {
              setShowEditModal(false);
            }}
            isOrg={props.isOrg}
          />
        ) : null}
        {props.canDelete ? (
          <DeleteModal
            vis={props.vis}
            show={showDeleteModal}
            onVisDelete={(localVis) => {
              setShowDeleteModal(false);
              props.onVisDelete(localVis);
            }}
            onCancel={() => {
              setShowDeleteModal(false);
            }}
            isOrg={props.isOrg}
          />
        ) : null}
        {showRecordModal ?
          <VideoRecordModal hasMic={hasCamMic.mic} hasCam={hasCamMic.cam} visUrl={props.vis.visualization_url} filebase={props.vis.title.replace(/ /g, "_")}
            show={showRecordModal} onHide={() => { setShowRecordModal(false) }} userInfo={userInfo} isOrg={props.isOrg} onUpload={(vis) =>{
              const tempVisCache = shallowCopy(visCache);
              tempVisCache[vis.id] = vis;
              setVisCache(tempVisCache);           
            }}/> : null
        }
        <ShareModal
          url={props.vis.visualization_url}
          show={showShareModal}
          canEmail={props.canEmail}
          qr={props.vis.qr_image}
          id={props.vis.id}
          onHide={() => {
            setShowShareModal(false);
          }}
        />
        {showAudioModal ? (
          <AudioModal
            vis={props.vis}
            show={showAudioModal}
            onVisEdit={(localVis, audioUrl, audioAutoplay) => {
              setShowAudioModal(false);
              props.onVisEdit(
                localVis,
                localVis.title,
                localVis.description,
                localVis.thumbnail,
                localVis.has_share_dialog,
                { audio_url: audioUrl, audio_autoplay: audioAutoplay }
              );
            }}
            onCancel={() => {
              setShowAudioModal(false);
            }}
            isOrg={props.isOrg}
          />
        ) : null}
        {showPreview ? (
          <Modal
            show={showPreview}
            onHide={() => {
              setShowPreview(false);
            }}
            dialogClassName="modal-90pct"
            contentClassName="w-100 h-100"
          >
            <Modal.Header closeButton closeVariant="white">
              Visual Preview
            </Modal.Header>
            <Modal.Body
              className="text-center d-flex flex-column"
              style={{ height: "100%" }}
            >
              <div
                className="d-flex flex-column"
                style={{
                  flexGrow: 1,
                  position: "relative",
                  overflow: "hidden",
                  width: "100%",
                  minWidth: "300px",
                  minHeight: "300px",
                }}
              >
                <iframe
                  src={props.vis.visualization_url + nocacheUrlSuffix}
                  id={`preview-modal-${props.vis.id}`}
                  className="border border-secondary rounded"
                  style={{
                    width: "100%",
                    height: "100%",
                    flexGrow: 1,
                  }}
                />
              </div>
            </Modal.Body>
          </Modal>
        ) : null}

        {showNewInitView ? (
          <InitialViewModal
            vis={props.vis}
            url={props.vis.visualization_url}
            onClose={() => setShowInitView(false)}
            isOrg={props.isOrg}
          />
        ) : null}
        {showSnapshotModal ? (
          <SnapshotModal
            vis={props.vis}
            url={props.vis.visualization_url}
            onClose={() => setShowSnapshotModal(false)}
            isOrg={props.isOrg}
            filebase={props.vis.title.replace(/ /g, "_")}
            onNewThumb={(newThumb) => {
              setShowEditModal(false);
              props.onVisEdit(
                props.vis,
                props.vis.title,
                props.vis.description,
                newThumb,
                props.vis.has_share_dialog
              );
            }}
            canEdit={props.canEdit}
          />
        ) : null}
        {showEditLandmarks ? (
          <LandmarkModal
            vis={props.vis}
            show={showEditModal}
            onVisEdit={(
              localVis,
              newTitle,
              newDesc,
              newThumb,
              newHasShare,
              other
            ) => {
              setShowEditLandmarks(false);
              props.onVisEdit(
                localVis,
                newTitle,
                newDesc,
                newThumb,
                newHasShare,
                other
              );
            }}
            onHide={() => {
              setShowEditLandmarks(false);
            }}
            isOrg={props.isOrg}
          />
        ) : null}
        <div
          className="my-1 p-3 w-100 vis-card position-relative"
          id={`vis-card-${props.vis.id}`}
        >
          <>
            <div className="position-absolute top-0 end-0">
              {props.canEdit ? (
                <FontAwesomeIcon
                  icon={faPencil}
                  className="icon-btnlike me-2"
                  size="xl"
                  aria-label="edit"
                  role="button"
                  tabIndex="0"
                  title={`Edit "${props.vis.title}"`}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setShowEditModal(true);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      event.stopPropagation();
                      setShowEditModal(true);
                    }
                  }}
                />
              ) : null}
              {props.canDelete ? (
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className="text-danger icon-btnlike"
                  size="xl"
                  aria-label="delete"
                  role="button"
                  tabIndex="0"
                  title={`Delete "${props.vis.title}"`}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setShowDeleteModal(true);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      setShowDeleteModal(true);
                    }
                  }}
                />
              ) : null}
            </div>
          </>
          {!props.hideFavorite ? (
            <FontAwesomeIcon
              icon={
                (favoriteCall ? !props.vis.favorite : props.vis.favorite)
                  ? faStarFill
                  : faStarEmpty
              }
              className={"icon-btnlike"}
              style={{
                position: "absolute",
                top: "-1rem",
                left: "-0.75rem",
                opacity: 1,
                backgroundColor: "var(--main-bg-color)",
                borderRadius: "50%",
                color: `rgba(${(favoriteCall ? !props.vis.favorite : props.vis.favorite)
                  ? "255, 193, 7"
                  : "var(--text-color)"
                  },${favoriteCall ? 0.5 : 1})`,
              }}
              size="lg"
              aria-label="edit"
              role={props.canEdit ? "button" : null}
              tabIndex={props.canEdit ? "0" : null}
              title={`${props.vis.favorite ? "Unfavorite" : "Favorite"} "${props.vis.title
                }"`}
              onClick={
                props.canEdit
                  ? (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (favoriteCall) {
                      return;
                    }

                    setFavoriteCall(true);
                    APIEditVisualizations(
                      props.vis,
                      null,
                      null,
                      null,
                      null,
                      () => {
                        props.onVisEdit(
                          props.vis,
                          props.vis.title,
                          props.vis.description,
                          null,
                          props.vis.has_share_dialog,
                          { favorite: !props.vis.favorite }
                        );
                        setFavoriteCall(false);
                      },
                      () => {
                        setFavoriteCall(false);
                      },
                      props.isOrg,
                      { favorite: !props.vis.favorite }
                    );
                  }
                  : null
              }
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  if (favoriteCall) {
                    return;
                  }
                  setFavoriteCall(true);
                  APIEditVisualizations(
                    props.vis,
                    null,
                    null,
                    null,
                    null,
                    () => {
                      props.onVisEdit(
                        props.vis,
                        props.vis.title,
                        props.vis.description,
                        null,
                        props.vis.has_share_dialog,
                        { favorite: !props.vis.favorite }
                      );
                    },
                    () => {
                      setFavoriteCall(false);
                    },
                    props.isOrg,
                    { favorite: !props.vis.favorite }
                  );
                }
              }}
              disabled={favoriteCall}
            />
          ) : null}
          <Row>
            <div className="col-2 col-md-1 pe-0">
              {props.label ? <h4>{props.label}</h4> : null}
              {props.canEdit || props.isOrg ? (
                <FontAwesomeIcon
                  icon={privacyIcon}
                  size="xl"
                  aria-label="share"
                  title={privacyTitle}
                  style={{ marginLeft: "-.5rem" }}
                />
              ) : null}
              {props.showTypeIcon ? (
                getIcon() == imageIcon ? (
                  <FontAwesomeIcon
                    icon={imageIcon}
                    title={getIconTitle()}
                    size="2xl"
                  />
                ) : (
                  <Image
                    className="dark-invert"
                    src={getIcon()}
                    style={{
                      width: "100%",
                      maxWidth: "3rem",
                      height: "auto",
                    }}
                    title={getIconTitle()}
                  />
                )
              ) : null}
            </div>
            <Row className="col-10 col-md-11">
              <Row>
                <Col
                  xs="auto"
                  className="flex-grow-1"
                  onClick={() => {
                    if (props.canShrink) {
                      setTitleOnly(true);
                    }
                  }}
                >
                  <h3 style={{ overflowWrap: "anywhere" }} className="me-3 vis-card-title" dir="auto">
                    {props.vis.title}
                  </h3>
                </Col>
                <Col className="text-end" xs="auto">
                  <span className="me-4 text-muted">{dateString}</span>
                  {expireString ? (
                    <>
                      <br />
                      <span className="me-4 text-danger">
                        Expires: {expireString}
                      </span>
                    </>
                  ) : null}
                </Col>
              </Row>
              <Col xs={12} sm={6} md={4} lg={3}>
                <Row className="vis-card-images">
                  {props.vis.thumbnail ? (
                    <Col
                      xs={6}
                      sm={12}
                      lg={props.hideQr ? 12 : 6}
                      className="mb-2"
                    >
                      <Image
                        src={props.vis.thumbnail}
                        alt={props.vis.title}
                        style={{
                          width: "100%",
                          height: "auto",
                          maxWidth: "10rem",
                          maxHeight: "10rem",
                        }}
                      />
                    </Col>
                  ) : null}
                  {!props.hideQr ? (
                    <Col
                      xs={6}
                      sm={12}
                      lg={props.vis.thumbnail ? 6 : 12}
                      className="mb-2"
                    >
                      {props.vis.qr_image ? (
                        <Image
                          className="vis-card-qrcode"
                          src={props.vis.qr_image}
                          style={{
                            width: "100%",
                            height: "auto",
                            maxWidth: "10rem",
                            maxHeight: "10rem",
                          }}
                          alt={props.vis.title + " QR Code"}
                        />
                      ) : null}
                    </Col>
                  ) : null}
                </Row>
              </Col>
              <Col xs={12} sm={6} md={8} lg={9}>
                <p style={{ overflowWrap: "anywhere" }}>
                  <b>Description: </b>
                  {<span className="vis-card-description" dir="auto" style={{whiteSpace: "pre-wrap"}}>{props.vis.description}</span>}
                </p>
                <b>{"Visual URL: "}</b>
                <a
                  href={props.vis.visualization_url}
                  target="_blank"
                  rel="noreferrer"
                  className="visualization_link me-3"
                  style={{ overflowWrap: "anywhere" }}
                >
                  {props.vis.visualization_url}
                </a>
                {navigator.clipboard ? (
                  <span>
                    <FontAwesomeIcon
                      icon={faClipboard}
                      size="xl"
                      aria-label="copy url"
                      role="button"
                      tabIndex="0"
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        navigator.clipboard
                          .writeText(props.vis.visualization_url)
                          .then(() => {
                            setCopyNotice("Copied URL");
                            setTimeout(() => {
                              setCopyNotice(null);
                            }, 1500);
                          });
                      }}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          navigator.clipboard
                            .writeText(props.vis.visualization_url)
                            .then(() => {
                              setCopyNotice("Copied URL");
                              setTimeout(() => {
                                setCopyNotice(null);
                              }, 1500);
                            });
                        }
                      }}
                      className="icon-btnlike"
                    />{" "}
                    {copyNotice}
                  </span>
                ) : null}
                {props.doubleExpire && expireString ? (
                  <div className="text-danger text-center mt-1">
                    <b>Expires: {expireString}</b>
                  </div>
                ) : null}
                <div className="mt-4">
                  {displayTags.map((tag) => (
                    <Link to={`/visuals/${tag.slug}`} key={tag.slug}>
                      <div className="rbt-token">
                        <div className="rbt-token-label">#{tag.title}</div>
                      </div>
                    </Link>
                  ))}
                </div>
              </Col>
            </Row>
          </Row>
          {props.canEdit && (props.isOrg || userInfo.visual_audio) && !props.vis.audio_locked ? (
            <Row className="justify-content-center align-items-center mb-3">
              <FontAwesomeIcon
                icon={faAudioIcon}
                label={`Set Audio Track for "${props.vis.title}"`}
                title={`Set Audio Track for "${props.vis.title}"`}
                size="2x"
                className="w-auto"
                onClick={() => {
                  setShowAudioModal(true);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    setShowAudioModal(true);
                  }
                }}
                tabIndex="-1"
              />
              {props.vis.audio_url ? (
                <audio
                  src={props.vis.audio_url}
                  controls
                  controlsList="nodownload"
                  style={{ maxWidth: "50%", width: "20rem", height: "1.5rem" }}
                />
              ) : (
                <>No Audio Track</>
              )}
            </Row>
          ) : null}
          <Row className="mt-2 mx-4 justify-content-center">
            {props.vis.can_save_view && props.canEdit ? (
              <Col xs="auto">
                <Button
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setShowInitView(true);
                  }}
                >
                  Change Initial View
                </Button>
              </Col>
            ) : null}
            {props.vis.can_landmark && props.canEdit ? (
              <Col xs="auto">
                <Button
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setShowEditLandmarks(true);
                  }}
                >
                  Add/Edit Landmarks
                </Button>
              </Col>
            ) : null}

            {props.addCopyToOrg ? (
              <Col xs="auto">
                <Button
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();

                    APIWebVisualization(
                      props.vis.id,
                      "snorkle",
                      props.vis.title,
                      null,
                      (body) => {
                        setOrgCopyState({
                          disable: true,
                          spinner: false,
                          message: "Copy created successfully.",
                          textClass: "text-success",
                        });
                        const tempVisCache = shallowCopy(visCache);
                        tempVisCache[body.id] = body;
                        setVisCache(tempVisCache);
                      },
                      (res) => {
                        let err_msg = "";
                        if (res.error) {
                          err_msg = res.error;
                        } else {
                          err_msg = res.toString();
                        }
                        setOrgCopyState({
                          disable: false,
                          spinner: false,
                          message: err_msg || "Unable to create copy.",
                          textClass: "text-danger",
                        });
                      },
                      true,
                      {
                        branding_profile: (
                          prepBrandProfileList(userInfo.branding_profiles)[0] ||
                          {}
                        ).id,
                      }
                    );
                    setOrgCopyState({ disable: true, spinner: true });
                  }}
                  disabled={orgCopyState.disable}
                >
                  {orgCopyState.spinner ? (
                    <Spinner
                      animation="border"
                      role="loading"
                      className="text-secondary align-center"
                      size="sm"
                    />
                  ) : (
                    "Add a copy of this to your organization portfolio"
                  )}
                </Button>
                {orgCopyState.message ? (
                  <div className={orgCopyState.textClass}>
                    {orgCopyState.message}
                  </div>
                ) : null}
              </Col>
            ) : null}
            <Col xs="auto">
              <Button
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setShowPreview(true);
                }}
              >
                Preview Visual
              </Button>
            </Col>
          </Row>
          <div className="position-absolute bottom-0 start-0 mb-3 ms-2">
            {props.vis.can_snapshot ? (
              <FontAwesomeIcon
                icon={faCamera}
                className="icon-btnlike me-2"
                size="xl"
                aria-label="snapshot"
                role="button"
                tabIndex="0"
                title={`Snapshot "${props.vis.title}"`}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setShowSnapshotModal(true);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    setShowSnapshotModal(true);
                  }
                }}
              />
            ) : null}
            {
              (props.canEdit && canRecordVideo(userInfo, props.isOrg)) ? (
                !!(navigator.mediaDevices.getUserMedia && navigator.mediaDevices.getDisplayMedia && HTMLCanvasElement.prototype.captureStream && MediaRecorder) ? (
                <FontAwesomeIcon
                  icon={faVideo}
                  className="icon-btnlike"
                  size="xl"
                  aria-label="video recording"
                  role="button"
                  tabIndex="0"
                  title={`Video recording of "${props.vis.title}"`}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setShowRecordModal(true);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      setShowRecordModal(true);
                    }
                  }}
                />) : (<FontAwesomeIcon
                  icon={faVideoSlash}
                  className="icon-btnlike text-muted"
                  size="xl"
                  aria-label="video recording not supported"
                  role="button"
                  tabIndex="0"
                  title={`Your browser does not support Snorkle video recording.`}
                />)
              ) : null
            }
          </div>
          <div className="position-absolute bottom-0 end-0 mb-3 me-2">
            {props.vis.downloadable && downloadStatus.state == "pending" ? (
              <Spinner
                animation="border"
                role="loading"
                className="text-secondary align-center  me-2"
                size="sm"
              />
            ) : null}
            {props.vis.downloadable && downloadStatus.state != "pending" ? (
              <>
                <FontAwesomeIcon
                  icon={faDownload}
                  ref={ttTarget}
                  className={
                    "icon-btnlike me-2" +
                    (downloadStatus.state == "success" ? " text-success" : "") +
                    (downloadStatus.state == "error" ? " text-danger" : "")
                  }
                  id={`download${props.vis.id}`}
                  size="xl"
                  aria-label="share"
                  title={`Download "${props.vis.title}"`}
                  role="button"
                  tabIndex="0"
                  onClick={(event) => {
                    setDownloadStatus({ state: "pending", msg: null });
                    APIGetDownloadableVersion(
                      props.vis,
                      (res) => {
                        downloadHtml(prepareFilename(props.vis.title), res);
                        setDownloadStatus({ state: "success", msg: null });
                      },
                      (err) => {
                        setDownloadStatus({
                          state: "error",
                          msg: err.message || err,
                        });
                      },
                      props.isOrg
                    );
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      setDownloadStatus({ state: "pending", msg: null });
                      APIGetDownloadableVersion(
                        props.vis,
                        (res) => {
                          downloadHtml(res);
                          setDownloadStatus({ state: "success", msg: null });
                        },
                        (err) => {
                          setDownloadStatus({
                            state: "error",
                            msg: err.message || err,
                          });
                        }
                      );
                    }
                  }}
                />{" "}
                <Overlay
                  target={ttTarget.current}
                  show={showTT}
                  placement="top"
                >
                  {(props) => (
                    <Tooltip id="overlay-example" {...props} show={props.show}>
                      {downloadStatus.msg}
                    </Tooltip>
                  )}
                </Overlay>
              </>
            ) : null}
            {props.vis.qr_image ? (
              <FontAwesomeIcon
                icon={faShareFromSquare}
                className="icon-btnlike"
                size="xl"
                aria-label="share"
                title={`Share "${props.vis.title}"`}
                role="button"
                tabIndex="0"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setShowShareModal(true);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    setShowShareModal(true);
                  }
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className={"p-3 my-1 " + (!titleOnly ? "d-none" : "")}>
        <Row>
          <Col xs={1} />
          <Col
            xs={11}
            onClick={() => {
              if (props.canShrink) {
                setTitleOnly(false);
              }
            }}
          >
            <h3 style={{ overflowWrap: "anywhere" }} className="me-3 vis-card-title collaped-title">
              {props.vis.title}
            </h3>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default VisCard;
