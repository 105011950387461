import { useState } from "react";
import { Modal, Form, Button, Row, Spinner } from "react-bootstrap";
import { APIEditVisualizations } from "../../helper/APIFunctions";
import { UserInfo, AvailableTags } from "../../helper/Context";
import TagSelector from "../TagSelector";
const EditModal = (props) => {
  const [isCallingAPI, setIsCallingAPI] = useState(false);
  const [editError, setEditError] = useState(null);
  const [newTitle, setNewTitle] = useState(props.vis.title);
  const [newDesc, setNewDesc] = useState(props.vis.description);
  const [newTags, setNewTags] = useState(props.vis.tags || []);
  const [newThumb, setNewThumb] = useState(null);
  const [newTTS, setNewTTS] = useState(!!props.vis.enable_tts);
  const [newHasShare, setNewHasShare] = useState(!!props.vis.has_share_dialog);
  const [hideBranding, setHideBranding] = useState(
    props.vis.has_branding ? !!props.vis.hide_branding : null
  );
  const [initialMsg, setInitialMsg] = useState(
    props.vis.initial_message || (props.vis.initial_message === "" ? "" : null)
  );
  const [hideControls, setHideControls] = useState(
    !!props.vis.hide_controls ||
    (props.vis.hide_controls === false ? false : null)
  );
  const [newPrivacy, setNewPrivacy] = useState(props.vis.privacy || "unlisted");
  const [newPassword, setNewPassword] = useState("");
  const [textureOrigin, setTextureOrigin] = useState(
    props.vis.texture_origin || null
  );
  const invalidThumb =
    newThumb && newThumb[0] && newThumb[0].size > 1024 * 1024 * 4;
  const validThumb =
    newThumb && newThumb[0] && newThumb[0].size <= 1024 * 1024 * 4;
  const onPrivacyChange = (e) => {
    if (e.target.checked) {
      const np = e.target.dataset.newPrivacy;
      setNewPrivacy(np);
    }
  };
  const invalidPass = (!newPassword && newPrivacy === "private" && props.vis.privacy !== "private") || (newPassword && newPassword.length < 8);
  return (
    <Modal show={props.show}>
      <Modal.Header>Edit Visual Info</Modal.Header>
      <Modal.Body>
        <p className="text-danger text-center">{editError}</p>
        <Form.Group controlId={props.vis.id + "-title"} className="mb-3">
          <Row>
            <Form.Label className="col-sm-3">Title</Form.Label>
            <Form.Control
              type="text"
              value={newTitle}
              placeholder="Visual Title"
              onChange={(event) => {
                setNewTitle(event.target.value);
              }}
              isInvalid={!newTitle || !newTitle.match(/\S/)}
              disabled={isCallingAPI}
            />
            <Form.Control.Feedback type="invalid">
              Title cannot be empty
            </Form.Control.Feedback>
          </Row>
        </Form.Group>
        <Form.Group controlId={props.vis.id + "-description"} className="mb-3">
          <Row>
            <Form.Label className="col-sm-3">Description</Form.Label>
            <Form.Control
              as="textarea"
              value={newDesc}
              placeholder="Optional Description for Visual"
              onChange={(event) => {
                setNewDesc(event.target.value);
              }}
              disabled={isCallingAPI}
              dir="auto"
              
            />
          </Row>
        </Form.Group>
        {props.vis.initial_message || props.vis.initial_message === "" ? (
          <Form.Group controlId={props.vis.id + "-welcome"} className="mb-3">
            <Row>
              <Form.Label className="col-sm-3">Introduction</Form.Label>
              <Form.Label className="col-sm-3 ms-auto font-weight-light text-muted">
                {initialMsg.length}/450
              </Form.Label>
              <Form.Control
                as="textarea"
                value={initialMsg}
                maxLength={450}
                placeholder="Optional introductory message for this visual. This will not be shown if the viewer is embedded in another page or PowerPoint&trade; presentation."
                onChange={(event) => {
                  setInitialMsg(event.target.value);
                }}
                disabled={isCallingAPI}
                dir="auto"
              />
            </Row>
            <Form.Check
              type="checkbox"
              label="Enable Text-to-Speech"
              checked={initialMsg && newTTS}
              onChange={(event) => {
                setNewTTS(event.target.checked);
              }}
              disabled={isCallingAPI || !initialMsg}
            />
          </Form.Group>
        ) : null}
        <Form.Group className="mb-3">
          <Row>
            <Form.Label className="col-auto">New Thumbnail</Form.Label>
            <Form.Control
              type="file"
              onChange={(event) => {
                setNewThumb(event.target.files);
              }}
              isInvalid={invalidThumb}
              accept=".png,.jpg,.jpeg"
              disabled={isCallingAPI}
            />
            <Form.Control.Feedback type="invalid">
              Selected file is too large.
            </Form.Control.Feedback>
            <Form.Text>
              Accepts .png, .jpg and .jpeg, files with a limit of 4MB
            </Form.Text>
          </Row>
          <Form.Text></Form.Text>
        </Form.Group>
        {!!props.vis.can_add_sharing ? (
          <Form.Group className="mb-3">
            <Row>
              <Form.Check
                type="checkbox"
                label="Add a share button to this visual."
                checked={newHasShare}
                onChange={(event) => {
                  setNewHasShare(event.target.checked);
                }}
                disabled={isCallingAPI}
              />
            </Row>
          </Form.Group>
        ) : null}
        {props.vis.hide_controls || props.vis.hide_controls === false ? (
          <Form.Group className="mb-3">
            <Row>
              <Form.Check
                type="checkbox"
                label="Hide the controls of this visual for the public."
                checked={hideControls}
                onChange={(event) => {
                  setHideControls(event.target.checked);
                }}
                disabled={isCallingAPI}
              />
            </Row>
          </Form.Group>
        ) : null}
        {props.vis.texture_origin ? (
          <Form.Group as={Row} className="mb-3">
            <Form.Label className="w-auto align-self-center">
              Texture Origin
            </Form.Label>
            <Form.Select
              className="w-auto flex-grow-1"
              value={textureOrigin}
              onChange={(e) => setTextureOrigin(e.target.value)}
              aria-label="Set Texture Origin"
            >
              <option value="BL">Bottom Left</option>
              <option value="TL">Top Left</option>
              <option value="BR">Bottom Right</option>
              <option value="TR">Top Right</option>
            </Form.Select>
          </Form.Group>
        ) : null}
        <UserInfo.Consumer>
          {({ userInfo, setUserInfo }) =>
            props.vis.has_branding &&
              (props.isOrg ||
                ["premium", "unlimited"].includes(userInfo.tier)) ? (
              <Form.Group className="mb-3">
                <Row>
                  <Form.Check
                    type="checkbox"
                    label="Hide the branding on this visual."
                    checked={hideBranding}
                    onChange={(event) => {
                      setHideBranding(event.target.checked);
                    }}
                    disabled={isCallingAPI}
                  />
                </Row>
              </Form.Group>
            ) : null
          }
        </UserInfo.Consumer>
        {!props.isVideo ? (
          <>
            <Form.Group>
              <Form.Label>Tags</Form.Label>
              <TagSelector
                onChange={(e) => {
                  setNewTags(e);
                }}
                defaultSelected={props.vis.tags || []}
                multiple
                disabled={isCallingAPI}
                title={"Tag Editor"}
                id={`title-selector-${props.vis.id}}`}
              />
            </Form.Group>
            <UserInfo.Consumer>
              {({ userInfo, setUserInfo }) =>
                (props.isOrg || ["premium", "unlimited"].includes(userInfo.tier)) ?
                  <Form.Group className="mt-3">
                    <Form.Label>Privacy</Form.Label>
                    <div>
                      <Form.Check
                        label="Public"
                        data-new-privacy="public"
                        checked={newPrivacy === "public"}
                        type="radio"
                        inline
                        onChange={onPrivacyChange}
                        name={`privacy-${props.vis.id}`}
                      />
                      <Form.Check
                        label="Unlisted"
                        type="radio"
                        data-new-privacy="unlisted"
                        checked={newPrivacy === "unlisted"}
                        inline
                        onChange={onPrivacyChange}
                        name={`privacy-${props.vis.id}`}
                      />
                      <Form.Check
                        label="Password Protected"
                        type="radio"
                        data-new-privacy="private"
                        checked={newPrivacy === "private"}
                        inline
                        onChange={onPrivacyChange}
                        name={`privacy-${props.vis.id}`}
                      />
                      {newPrivacy === "private" ? <>
                        <Form.Label className="col-sm-3">Password</Form.Label>
                        <Form.Control
                          type="password"
                          value={newPassword}
                          placeholder="*************"
                          onChange={(event) => {
                            setNewPassword(event.target.value);
                          }}
                          isInvalid={invalidPass}
                          disabled={isCallingAPI}
                        />
                        <Form.Control.Feedback type="invalid">
                          {!newPassword ? "Password must be provided" : "Password must be at least 8 characters"}
                        </Form.Control.Feedback></> : null}
                    </div>
                  </Form.Group> : null}
            </UserInfo.Consumer>
          </>
        ) : null}
        <p>
          <small>Visuals may take up to 15 minutes to reflect changes.</small>
        </p>
        <Button
          onClick={(event) => {
            setIsCallingAPI(true);
            setEditError(null);
            const onRead = (thumbData) => {
              const other = {};
              if (hideControls !== null) {
                other.hideControls = hideControls;
              }
              if (initialMsg !== null) {
                other.initialMessage = initialMsg;
              }
              other.enableTts = !!(initialMsg && newTTS);
              if (hideBranding !== null) {
                other.hideBranding = hideBranding;
              }
              if (newPrivacy) {
                other.privacy = newPrivacy;
              }
              if (textureOrigin) {
                other.textureOrigin = textureOrigin;
              }
              if (newPassword) {
                other.newPassword = newPassword;
              }
              other.tags = newTags.map((tag) => tag.slug);
              APIEditVisualizations(
                props.vis,
                newTitle,
                newDesc,
                thumbData,
                newHasShare,
                (res) => {
                  props.onVisEdit(
                    props.vis,
                    newTitle,
                    newDesc,
                    thumbData,
                    newHasShare,
                    other
                  );
                  setIsCallingAPI(false);
                  setEditError(null);
                  setNewPassword("");
                },
                (err) => {
                  setEditError(err.error || err || "Internal Server Error.");
                  setIsCallingAPI(false);
                },
                props.isOrg,
                other
              );
            };
            if (!validThumb) {
              onRead(null);
            } else {
              const newReader = new FileReader();
              newReader.onload = (event) => {
                onRead(newReader.result);
              };
              newReader.onerror = () => {
                setEditError("Unable to read thumbnail file.");
                setIsCallingAPI(false);
              };
              newReader.readAsDataURL(newThumb[0]);
            }
          }}
          disabled={isCallingAPI || !newTitle || invalidThumb}
        >
          {isCallingAPI ? (
            <Spinner
              animation="border"
              role="loading"
              className="text-secondary align-center"
              size="sm"
            />
          ) : (
            <>Confirm</>
          )}
        </Button>
        <Button
          onClick={(event) => {
            setNewTitle(props.vis.title);
            setNewDesc(props.vis.description);
            setNewThumb(null);
            props.onCancel(false);
          }}
          disabled={isCallingAPI}
        >
          Cancel
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default EditModal;
