import React, { useState, useEffect } from "react";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  APIGetTags,
  APIGetBundles,
  APIGetVisualizations,
  APIGetOrgVisuals,
} from "./helper/APIFunctions";
import { shallowCopy } from "./helper/Utils";

import Header from "./components/Header";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import UseCasesPage from "./pages/UseCasesPage";
import Footer from "./components/Footer";
import PowerPointPage from "./pages/PowerPointPage";
import UploadPage from "./pages/UploadPage";
import PasswordResetPage from "./pages/PasswordResetPage";
import ProfilePage from "./pages/ProfilePage";
import PricingPage from "./pages/PricingPage";
import TermsPage from "./pages/TermsPage";
import PrivacyPage from "./pages/PrivacyPage";
import ContactPage from "./pages/ContactPage";
import DashboardPage from "./pages/DashboardPage";
import DeletedVisualsPage from "./pages/DeletedVisualsPage";
import PaymentSuccessPage from "./pages/PaymentSuccessPage";
import AdminPage from "./pages/AdminPage.js";
import BundlePage from "./pages/BundlesPage.js";
import GNYDM2023Page from "./pages/GNYDM2023Page.js";

import {
  DisclaimerSigned,
  UserInfo,
  ActiveTour,
  AvailableTags,
  TagLookup,
  StripePromise,
  VisualCache,
  Bundles,
} from "./helper/Context";
import JoyrideWrapper from "./components/JoyrideWrapper";
import TutorialsPage from "./pages/TutorialsPage";
import OrganizationInfoPage from "./pages/OrganizationInfoPage";
import OrganizationJoinPage from "./pages/OrganizationJoinPage";
import MessageCenter  from "./components/MessageCenter.js";
import SearchPage from "./pages/SearchPage";
import TaggedVisualsPage from "./pages/TaggedVisualsPage";
import AddTagsPage from "./pages/AddTagsPage";
import TagListPage from "./pages/TagListPage";
import { loadStripe } from "@stripe/stripe-js";

import { canRestoreVisuals, getDefaultPage } from "./helper/BusinessLogic";

const App = (props) => {
  const [userInfo, setUserInfo] = useState(props.userInfo);
  const [disclaimerSigned, setDisclaimerSigned] = useState(!!userInfo);
  const [activeTour, setActiveTour] = useState(null);
  const [availableTags, setAvailableTags] = useState(null);
  const [tagLookup, setTagLookup] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);
  const [visCache, setVisCache] = useState({});
  const [bundles, setBundles] = useState(null);
  const [cachedVisEmail, setCachedVisEmail] = useState(null);
  let location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    APIGetTags(
      (res) => {
        res.sort((a, b) =>
          a.title.toLowerCase().localeCompare(b.title.toLowerCase())
        );
        setAvailableTags(res);
        const dict = {};
        res.forEach((tag) => (dict[tag.slug] = tag));
        setTagLookup(dict);
      },
      () => {}
    );
    APIGetBundles(
      (res) => {
        setBundles(res);
      },
      () => {}
    );
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY));
    const url = getDefaultPage(props.userInfo);
    if (["/", "/portal", "/home"].some((s) => s === location.pathname)) {
      navigate(url);
    }
  }, []);

  useEffect(() => {
    if (!userInfo) {
      setVisCache({});
      setCachedVisEmail(null);
    } else if (userInfo && userInfo.email != cachedVisEmail) {
      setCachedVisEmail(userInfo.email);
      let temp = {};
      APIGetVisualizations(
        (body) => {
          const visList = body.visuals || body;
          for (const v of visList) {
            v.isOrgVis = false;
            temp[v.id] = v;
          }
          temp["personalVises"] = true;
          if (userInfo.organization_permissions) {
            APIGetOrgVisuals(
              (body2) => {
                const visList = body2.visuals || body2;
                for (const v of visList) {
                  v.isOrgVis = true;
                  temp[v.id] = v;
                }
                temp["orgVises"] = true;
                setVisCache(temp);
              },
              () => {temp["orgVises"] = false;}
            );
          } else {
            temp["orgVises"] = true;
            setVisCache(temp);
          }
        },
        () => {temp["personalVises"] = false;}
      );
    }
  }, [userInfo]);

  return (
    <DisclaimerSigned.Provider
      value={{ disclaimerSigned, setDisclaimerSigned }}
    >
      <UserInfo.Provider value={{ userInfo, setUserInfo }}>
        <ActiveTour.Provider value={{ activeTour, setActiveTour }}>
          <AvailableTags.Provider value={{ availableTags, setAvailableTags }}>
            <TagLookup.Provider value={{ tagLookup, setTagLookup }}>
              <VisualCache.Provider value={{ visCache, setVisCache }}>
                <Bundles.Provider value={{ bundles, setBundles }}>
                  <StripePromise.Provider value={stripePromise}>
                    <div className="main-container">
                      <Header />
                      <JoyrideWrapper />
                      <div className="m-4 flex-grow-1" id="main-body">
                        <Routes>
                          <Route path="home" element={<HomePage />} />
                          <Route path="about" element={<AboutPage />} />
                          <Route path="portal" element={<HomePage />} />
                          <Route path="usecases" element={<UseCasesPage />} />
                          <Route
                            path="powerpoint"
                            element={<PowerPointPage />}
                          />
                          <Route
                            path="passwordreset/:token"
                            element={<PasswordResetPage />}
                          />
                          {/* <Route
                  path="guestupload/*"
                  element={
                    <UploadPage
                      breadcrumbs={[
                        { name: "Home", url: "/home" },
                        { name: "Create Visual", url: "/guestupload" },
                      ]}
                    />
                  }
                /> */}
                          <Route
                            path="dashboard/*"
                            element={
                              userInfo !== null ? (
                                <DashboardPage />
                              ) : (
                                <Navigate to="/home" />
                              )
                            }
                          />
                          <Route path="pricing" element={<PricingPage />} />
                          <Route path="privacy" element={<PrivacyPage />} />
                          <Route path="tos" element={<TermsPage />} />
                          <Route path="contact" element={<ContactPage />} />
                          <Route
                            path="tutorials"
                            element={
                              userInfo ? (
                                <TutorialsPage />
                              ) : (
                                <Navigate to="/home" />
                              )
                            }
                          />
                          <Route
                            path="organization/visuals"
                            element={
                              userInfo && userInfo.organization_permissions ? (
                                <ProfilePage isOrg={true} />
                              ) : (
                                <Navigate to="/home" />
                              )
                            }
                          />
                          <Route
                            path="organization/upload/*"
                            element={
                              userInfo &&
                              userInfo.organization_permissions &&
                              userInfo.organization_permissions.add_visuals ? (
                                <UploadPage
                                  isOrg={true}
                                  breadcrumbs={[
                                    { name: "Home", url: "/home" },
                                    {
                                      name: "Organization",
                                      url: "/organization/info",
                                    },
                                    {
                                      name: "Create Visual",
                                      url: "/organization/upload",
                                    },
                                  ]}
                                />
                              ) : (
                                <Navigate to="/home" />
                              )
                            }
                          />
                          <Route
                            path="organization/info"
                            element={
                              userInfo && userInfo.organization_permissions ? (
                                <OrganizationInfoPage />
                              ) : (
                                <Navigate to="/home" />
                              )
                            }
                          />
                          <Route
                            path="organization/join/:encodedName/:joinToken"
                            element={<OrganizationJoinPage />}
                          />
                          <Route path="search" element={<SearchPage />} />
                          <Route
                            path="/visuals/:slug"
                            element={<TaggedVisualsPage />}
                          />
                          <Route
                            path="/visuals"
                            exact={true}
                            element={<TaggedVisualsPage />}
                          />
                          <Route
                            path="/tags"
                            element={
                              userInfo && userInfo.can_add_tags ? (
                                <TagListPage />
                              ) : (
                                <Navigate to="home" />
                              )
                            }
                          />
                          <Route path="/admin/*" element={<AdminPage />} />
                          <Route
                            path="/special/gnydm2023"
                            element={<GNYDM2023Page />}
                          />
                          <Route
                            path="/tags/add"
                            element={
                              userInfo && userInfo.can_add_tags ? (
                                <AddTagsPage />
                              ) : (
                                <Navigate to="home" />
                              )
                            }
                          />
                          <Route
                            path="/deletedvisuals"
                            element={
                              userInfo && canRestoreVisuals(userInfo.tier) ? (
                                <DeletedVisualsPage />
                              ) : (
                                <Navigate to="home" />
                              )
                            }
                          />
                          <Route
                            path="/payment/:newTier"
                            element={<PaymentSuccessPage />}
                          />
                          <Route path="/bundles" element={<BundlePage />} />
                          <Route index element={<Navigate to="/home" />} />
                          <Route path="*" element={<Navigate to="/home" />} />
                        </Routes>
                      </div>
                      <MessageCenter />
                      <footer className="ps-4 pe-3 footer mt-auto">
                        <Footer />
                      </footer>
                    </div>
                  </StripePromise.Provider>
                </Bundles.Provider>
              </VisualCache.Provider>
            </TagLookup.Provider>
          </AvailableTags.Provider>
        </ActiveTour.Provider>
      </UserInfo.Provider>
    </DisclaimerSigned.Provider>
  );
};

export default App;
