import React, { useState, useLayoutEffect } from "react";
import ReactPlayer from "react-player";
import { Button, Col, } from "react-bootstrap";
import { Link } from "react-router-dom";
import { UserInfo } from "../helper/Context";
import LoginModal from "../components/LoginModal";
import SearchForm from "../components/SearchForm";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";

const HomePage = (props) => {
  const resizePlayer = () => {
    const containerDom = document.getElementById("home-react-player");
    const playerDom = document.getElementById("home-react-player-video");
    const header = document.getElementsByTagName("nav")[0];
    const footer = document.getElementsByTagName("footer")[0];
    const collapse = document.getElementsByClassName("navbar-collapse")[0];
    const sizingDiv = document.getElementById("sizing-div");
    sizingDiv.style.height = (window.innerHeight - header.offsetHeight - footer.offsetHeight + (collapse.classList.contains("show") ? collapse.offsetHeight : 0)) + "px";
    const aspectRatio = 16 / 9;
    const containerHeight = containerDom.offsetHeight;
    const containerWidth = containerDom.offsetWidth;
    const newWidth = Math.max(containerWidth, containerHeight * aspectRatio);
    const newHeight = Math.max(containerHeight, containerWidth / aspectRatio);
    playerDom.style.width = newWidth + "px";
    playerDom.style.height = newHeight + "px";
    playerDom.style.left = (containerWidth - newWidth) / 2 + "px";
    playerDom.style.top = (containerHeight - newHeight) / 2 + "px";
  };
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [defaultTab, setDefaultTab] = useState("login");
  useLayoutEffect(() => {
    window.addEventListener("resize", resizePlayer);
    resizePlayer();
    return () => window.removeEventListener("resize", resizePlayer);
  }, []);

  function gotoAddInSite() {
    window.open(
      "https://support.microsoft.com/en-us/office/add-or-load-a-powerpoint-add-in-3de8bbc2-2481-457a-8841-7334cd5b455f",
      "_blank"
    );
  }

  return (
    <>
      <LoginModal
        isOpen={showLoginModal}
        onClose={() => {
          setShowLoginModal(false);
        }}
        onClosed={() => {
          setTimeout(() => { document.activeElement.blur(); }, 10)
        }}
        defaultTab={defaultTab}
      />
      <div id="sizing-div" style={{ margin: "-1.5rem" }} />
      <div
        id="home-react-player"
        style={{
          width: "calc(100% + 3rem)",
          height: "calc(100% + 3rem)",
          position: "absolute",
          top: "-1.5rem",
          left: "-1.5rem",
          overflow: "hidden",
        }}
      >
        <ReactPlayer
          className="react-player hidden"
          id="home-react-player-video"
          playing={true}
          playsinline={true}
          controls={false}
          width="100%"
          height="100%"
          loop={true}
          muted={true}
          // config={{ vimeo: { playerOptions: { responsive: true } } }}
          // url="https://vimeo.com/730472517"
          url="https://vimeo.com/913779120"
          onPlay={() => {
            document.getElementById("home-react-player-video").classList.remove("hidden");
            setTimeout(() => {
              const p = document.getElementById("home-react-player");
              if (p) { p.style.backgroundImage = "none" }
            }
              , 750);
          }}
        />
      </div>
      <div id="home-header"
        className="TitleText text-light mb-4 col-12"
        // Commented below on 2/24/24
        style={{ position: "absolute", top: "-0.5rem" }}
      >
        <h10 className="fade-in-text">A canvas for expert visuals, a window into verified science for everyone</h10>
        {/* <h10>A visual stage for experts, a window into verified science for everyone</h10> */}
      </div>
      {/* <br></br>
      <br></br>
      <br></br>
      <br></br> */}
      <div
        // Commented below on 2/24/24
        className="mt-auto w-100 text-center"
        id="home-page-contents"
        // Commented below on 2/24/24
        style={{ position: "absolute", bottom: "-0.5rem" }}
      >
        <div className="w-100 text-center" id="search-home-container">
          <h5 className="SearchLabelText mb-1">
            Search all of science and medicine
          </h5>
          <SearchForm />
        </div>
        <UserInfo.Consumer>
          {({ userInfo, setUserInfo }) => {
            const isLoggedIn = userInfo !== null;
            return isLoggedIn ? (
              <>
                <Link to="/dashboard/myvisuals" tabIndex={-1}>
                  <Button>My Visuals</Button>
                </Link>
                <Link to="/dashboard/upload" tabIndex={-1}>
                  <Button>Create a Visual</Button>
                </Link>
              </>
            ) : (
              <>
                <Button
                  className=""
                  onClick={(event) => {
                    setDefaultTab("login");
                    setShowLoginModal(true);
                  }}
                >
                  Login
                </Button>

                <Button
                  className="bg-success"
                  onClick={(event) => {
                    setDefaultTab("register");
                    setShowLoginModal(true);
                  }}
                >
                  Register for Free!
                </Button>

              </>
            );
          }}
        </UserInfo.Consumer>
      </div>
      <div id="crossref-logo" style={{ width: '170px', minWidth: '70px', maxWidth: '15%', height: 'auto', position: "absolute", bottom: "-1.5rem", right: "-1.5rem" }}>
        <a href="https://www.crossref.org/about" target="_blank" rel="noreferrer" >
          <img src="https://assets.crossref.org/logo/crossref-logo-200.svg" id="crossref-logo" title="Crossref Content Registration"   ></img>
        </a>
      </div>
    </>
  );
};

export default HomePage;