import { useState, useContext, useEffect, } from "react";
import { download, shallowCopy } from "../../helper/Utils";
import { Modal, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faVideo, faMicrophoneSlash, faVideoSlash, faX } from "@fortawesome/free-solid-svg-icons";
import { faCircleDot as faRecordIcon } from "@fortawesome/free-regular-svg-icons";
import { canUploadVideo } from "../../helper/BusinessLogic";
import { APIFileVisualization } from "../../helper/APIFunctions";
const RecordOptions = (props) => {
    const [options, setOptions] = useState(props.initValues);
    const [noCamText, setNoCamText] = useState(props.hasCam);
    const [noMicText, setNoMicText] = useState(props.hasMic);
    const updateVal = (name, newVal) => {
        const t = shallowCopy(options);
        t[name] = newVal;
        setOptions(t);
        props.onChange(t);
    }
    const getCamText = () => {
        if (noCamText) {
            return "No camera found. If you want to have a camera view, please make sure that it is connected and press this button to check again.";
        }
        if (options.useWebcam) {
            return "Camera enabled. Click to disable."
        }
        else {
            return "Camera disabled. Click to enable."
        }
    }
    const getMicText = () => {
        if (noMicText) {
            return "No microphone found. If you want to have voice recording, please make sure that it is connected and press this button to check again.";
        }
        if (options.useMicrophone) {
            return "Microphone enabled. Click to disable."
        }
        else {
            return "Microphone disabled. Click to enable."
        }
    }
    return (<>
        <div style={{ display: 'flex', width: "100%", textAlign: "center" }} className="my-2">
            <Button style={{ flexGrow: "1" }} className={"w-25  mx-4" + (options.useMicrophone ? "" : " text-danger")} title={getMicText()} onClick={() => {
                navigator.mediaDevices.enumerateDevices().then(res => {
                    const hasAudio = res.some(r => r.kind == "audioinput");
                    setNoMicText(!hasAudio);
                    updateVal("useMicrophone", hasAudio && !options.useMicrophone);
                })
            }}>
                <FontAwesomeIcon icon={options.useMicrophone ? faMicrophone : faMicrophoneSlash} size="2xl" />
            </Button>
            <Button className={"mx-4 w-25" + (options.useWebcam ? "" : " text-danger")} title={getCamText()} style={{ flexGrow: "1" }} onClick={() => {
                navigator.mediaDevices.enumerateDevices().then(res => {
                    const hasVideo = res.some(r => r.kind == "videoinput");
                    setNoCamText(!hasVideo);
                    updateVal("useWebcam", hasVideo && !options.useWebcam);
                })
            }}>
                <FontAwesomeIcon icon={options.useWebcam ? faVideo : faVideoSlash} size="2xl" />
            </Button>
        </div>
        {options.useWebcam ?
            <Form.Select className="mx-0 my-2" value={options.camOrientation} onInput={(e) => updateVal("camOrientation", parseInt(e.target.value))} >
                <option value="0">Facecam: Bottom Left</option>
                <option value="1">Facecam: Bottom Right</option>
                <option value="2">Facecam: Top Left</option>
                <option value="3">Facecam: Top Right</option>
            </Form.Select> : null}
    </>
    )
}

const VideoRecorder = (props) => {
    let recorderOptions = { useMicrophone: props.hasMic, useWebcam: props.hasCam, camOrientation: 0 };
    let mediaObjects = {};
    const initTitle = document.title;
    const initStream = () => {
        const screenDiv = document.createElement("div");
        screenDiv.style.backgroundColor = "rgba(0,0,0,0.6)";
        screenDiv.style.position = "absolute";
        screenDiv.style.top = "0px";
        screenDiv.style.left = "0px";
        screenDiv.style.right = "0px";
        screenDiv.style.bottom = "0px";
        screenDiv.style.zIndex = 9999999;
        screenDiv.style.textAlign = "center";
        screenDiv.style.display = "flex";
        screenDiv.style.justifyContent = "center";
        screenDiv.style.alignItems = "center";
        screenDiv.style.color = "#ffffff";
        screenDiv.style.fontSize = "600%";
        screenDiv.innerText = "Initializing...";
        document.title = "Initializing..."
        document.getElementById(`video-record-body-${props.visUrl}`).appendChild(screenDiv);
        mediaObjects = {};
        mediaObjects.countdownDiv = screenDiv;
        const useMicrophone = recorderOptions.useMicrophone;
        const useWebcam = recorderOptions.useWebcam
        let promise = navigator.mediaDevices.getDisplayMedia({ video: { displaySurface: "browser" }, preferCurrentTab: true, selfBrowserSurface: "include", systemAudio: "exclude" }).then((res) => {
            mediaObjects.canvasStream = res;
            const videoTrack = res.getVideoTracks()[0];
            videoTrack.addEventListener("ended", stopRec);
            mediaObjects.stream = new MediaStream(res);
        });
        if (useMicrophone || useWebcam) {
            promise = promise.then(() => {
                return navigator.mediaDevices.getUserMedia({ video: useWebcam, audio: useMicrophone }).then(
                    (res) => {
                        mediaObjects.userStream = res;
                        for (const st of res.getTracks()) {
                            st.addEventListener("ended", stopRec);
                        }
                        return mediaObjects.stream;
                    }
                )
            });
        }
        return promise;
    }
    const startRecCountdown = () => {
        document.getElementById("recorder-options").style.display = "none";
        document.getElementById(`record-modal-close-${props.visURL}`).style.display = "none";
        if (mediaObjects.userStream && mediaObjects.userStream.getVideoTracks().length) {
            const canvasVideoTrack = mediaObjects.canvasStream.getVideoTracks()[0];
            const insetMWidth = canvasVideoTrack.getSettings().width / 4;
            const insetMHeight = canvasVideoTrack.getSettings().height / 4.;
            const userVideoTrack = mediaObjects.userStream.getVideoTracks()[0];
            const pixelRatio = canvasVideoTrack.getSettings().width / window.innerWidth;
            const insetWidth = Math.round(Math.min(insetMWidth, insetMHeight * userVideoTrack.getSettings().aspectRatio) / (pixelRatio || 1));
            const insetHeight = Math.round(Math.min(insetMHeight, insetMWidth / userVideoTrack.getSettings().aspectRatio) / (pixelRatio || 1));
            mediaObjects.webcamVideoElem = document.createElement("video");
            mediaObjects.webcamVideoElem.autoplay = true;
            mediaObjects.webcamVideoElem.muted = true;
            mediaObjects.webcamVideoElem.srcObject = mediaObjects.userStream;
            mediaObjects.webcamVideoElem.style.pointerEvents = "none";
            mediaObjects.webcamVideoElem.style.position = "absolute";
            mediaObjects.webcamVideoElem.style.zIndex = "999999999";
            mediaObjects.webcamVideoElem.style[recorderOptions.camOrientation & 2 ? "top" : "bottom"] = "0px";
            mediaObjects.webcamVideoElem.style[recorderOptions.camOrientation & 1 ? "right" : "left"] = "0px";
            mediaObjects.webcamVideoElem.style.width = insetWidth + "px";
            mediaObjects.webcamVideoElem.style.height = insetHeight + "px";
            mediaObjects.webcamVideoElem.style.border = "2px solid black";
            document.getElementById(`video-record-body-${props.visUrl}`).appendChild(mediaObjects.webcamVideoElem);
        }
        if (mediaObjects.userStream) {
            for (const at of mediaObjects.userStream.getAudioTracks()) {
                mediaObjects.stream.addTrack(at);
            }
        }
        let videoMime = "";
        for (const mimeCandidate of ["video/mp4", "video/webm"]) {
            if (MediaRecorder.isTypeSupported(mimeCandidate)) {
                videoMime = mimeCandidate;
                break;
            }
        }
        window.snokrle = window.snokrle || {};

        mediaObjects.videoBlobs = [];
        mediaObjects.recorder = new MediaRecorder(mediaObjects.stream, { mimeType: videoMime });
        mediaObjects.recorder.addEventListener("dataavailable", (b) => {
            mediaObjects.videoBlobs.push(b.data);
            if (mediaObjects.recorder.state == "inactive") {
                const videoUrl = URL.createObjectURL(mediaObjects.videoBlobs[mediaObjects.videoBlobs.length - 1]);
                mediaObjects.finalVideo = document.createElement("video");
                mediaObjects.finalVideo.controls = true;
                mediaObjects.finalVideo.downloadable = true;
                mediaObjects.offscreenCanvas = null;
                mediaObjects.finalVideo.style.width = "50vw";
                mediaObjects.finalVideo.style.height = "50vh";
                const screenDiv = document.createElement("div");
                screenDiv.classList.add("dark-mode");
                screenDiv.style.backgroundColor = "rgba(0,0,0,0.8)";
                screenDiv.style.position = "absolute";
                screenDiv.style.top = "0px";
                screenDiv.style.left = "0px";
                screenDiv.style.right = "0px";
                screenDiv.style.bottom = "0px";
                screenDiv.style.zIndex = 9999999;
                const containerDiv = document.createElement("div");
                containerDiv.style.position = "absolute";
                containerDiv.style.top = "50%";
                containerDiv.style.left = "50%";
                containerDiv.style.transform = "translate(-50%,-50%)";
                screenDiv.appendChild(containerDiv)
                mediaObjects.finalVideo.src = videoUrl;
                containerDiv.appendChild(mediaObjects.finalVideo);
                const a = document.createElement("a");
                const buttonDiv = document.createElement("div");
                containerDiv.appendChild(buttonDiv);
                buttonDiv.style.textAlign = "center";
                a.href = videoUrl;
                a.download = props.filebase;
                const b1 = document.createElement("button");
                b1.classList.add("btn", "btn-primary", "m-4");
                b1.innerText = "Download Video";
                a.appendChild(b1);
                buttonDiv.appendChild(a);
                if (canUploadVideo(props.userInfo, false)) {
                    const b3 = document.createElement("button");
                    b3.innerText = "Upload Video";
                    const textDiv = document.createElement("div");
                    textDiv.style.fontSize = "250%";
                    textDiv.style.textAlign = "center";
                    b3.addEventListener("click", () => {
                        const blob = mediaObjects.videoBlobs[mediaObjects.videoBlobs.length - 1];
                        window.uploadBlob = blob;
                        blob.name = props.filebase + (("." + blob.type.split("/")[1].split(";")[0]) || ".webm");
                        APIFileVisualization(blob,
                            props.filebase,
                            null,
                            (res) => {
                                textDiv.innerText = "Success";
                                textDiv.classList.remove("text-danger");
                                textDiv.classList.add("text-success");
                                props.onUpload(res);
                                setTimeout(exitAction, 3000);
                            },
                            () => {
                                textDiv.innerText = "Error";
                                textDiv.classList.add("text-danger");
                                textDiv.classList.remove("text-success");
                                b3.disabled = false;
                                b2.disabled = false;
                            },
                            false
                        );
                        b2.disabled = true;
                        b3.disabled = true;
                        b3.innerHTML =`<div class="spinner-border text-secondary spinner-border-sm" role="status">
                            <span class="sr-only">Uploading...</span>
                        </div>`
                    });
                    b3.classList.add("btn", "btn-primary", "m-4");
                    buttonDiv.appendChild(b3);
                    containerDiv.appendChild(textDiv);
                }
                const b2 = document.createElement("button");
                b2.innerText = "Discard Video";
                b2.addEventListener("click", () => {
                    screenDiv.remove();
                    mediaObjects = {};
                    URL.revokeObjectURL(videoUrl);
                    document.getElementById("recorder-options").style.display = "";
                    document.getElementById(`record-modal-close-${props.visURL}`).style.display = "";
                });
                buttonDiv.appendChild(b2);
                b2.classList.add("btn", "btn-primary", "m-4", "bg-danger");
                document.getElementById(`video-record-body-${props.visUrl}`).appendChild(screenDiv);
            }
        });
        const screenDiv = mediaObjects.countdownDiv;
        screenDiv.innerText = "3";
        document.title = "3";
        document.getElementById(`video-record-body-${props.visUrl}`).appendChild(screenDiv);
        const startRecDelay = 150.;
        mediaObjects.countdownTimeout = setTimeout((
        ) => {
            screenDiv.innerText = "2";
            document.title = "2";
            mediaObjects.countdownTimeout = setTimeout(() => {
                screenDiv.innerText = "1";
                document.title = "1";
                mediaObjects.countdownTimeout = setTimeout(() => {
                    screenDiv.remove();
                    delete mediaObjects.countdownDiv;
                    mediaObjects.countdownTimeout = setTimeout(() => {
                        mediaObjects.recorder.start();
                        document.title = "Recording...";
                    }, startRecDelay);
                }, 1000 - startRecDelay)
            }, 1000);
        }, 1000);
    }
    const stopRec = () => {
        let resetMediaObject = true;
        clearTimeout(mediaObjects.countdownTimeout);
        if (mediaObjects.recorder && mediaObjects.recorder.state != "inactive") {
            mediaObjects.recorder.stop();
            resetMediaObject = false;
        }
        document.title = initTitle;
        if (mediaObjects.canvasStream) {
            for (const st of mediaObjects.canvasStream.getTracks()) {
                st.stop();
            }
        }
        if (mediaObjects.userStream) {
            for (const st of mediaObjects.userStream.getTracks()) {
                st.stop();
            }
        }
        if (mediaObjects.frameTickHandler) {
            clearInterval(mediaObjects.frameTickHandler);
            mediaObjects.frameTickHandler = null;
        }
        for (const e of [mediaObjects.offscreenCanvas, mediaObjects.webcamVideoElem, mediaObjects.canvasVideoElem, mediaObjects.countdownDiv]) {
            if (e && e.remove) {
                e.remove();
            }
        }
        if (resetMediaObject) {
            mediaObjects = {};
            document.getElementById("recorder-options").style.display = "";
            document.getElementById(`record-modal-close-${props.visURL}`).style.display = "";
            document.title = initTitle;
        }
    }
    const exitAction = () => {
        stopRec();
        props.onHide();
    }
    const popstateHandler = (data) => {
        exitAction();
    }
    useEffect(() => {
        window.history.pushState({ key: "returnToProfile" }, "");
        window.addEventListener("popstate", popstateHandler);
        return (() => { window.removeEventListener("popstate", popstateHandler) });
    }, []);
    return (
        <Modal show={props.show} fullscreen={true} keyboard={false}
            onEscapeKeyDown={() => {
                if (mediaObjects && mediaObjects.canvasStream && mediaObjects.canvasStream.active) {
                    stopRec();
                }
                else {
                    props.onHide();
                }
            }}
            onkeydown={(e) => {
                if (e.key == "Escape") {
                    exitAction()
                }
            }}>
            <Modal.Body style={{ position: "absolute", left: "0px", right: "0px", top: "0px", bottom: "0px", margin: "0px", padding: "0px", overflow: "hidden" }} id={`video-record-body-${props.visUrl}`}>
                <button id={`record-modal-close-${props.visURL}`} className="secret-button" style={{ position: "absolute", top: "6px", right: "6px", backgroundColor: "var(--main-bg-color) !important", opacity: "0.75", borderRadius: "35%" }} title="Close" onClick={() => { stopRec(); props.onHide() }}>
                    <FontAwesomeIcon size="xl" icon={faX} style={{ opacity: "1", aspectRatio: "1" }}></FontAwesomeIcon>
                </button>
                <iframe src={props.visUrl} style={{ width: "100%", height: "100%" }}></iframe>
                <div id="recorder-options" className="dark-mode" style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%,-50%)", textAlign: "center" }}>
                    <div style={{ position: "relative", width: "100%", height: "100%", position: "absolute", zIndex: "-1", backgroundColor: "var(--main-bg-color)", opacity: "0.85" }} />
                    <div className="my-2 mx-2">Use the bottom below to start recording. You can use the browser's "Stop sharing" button to stop recording.</div>
                    <RecordOptions initValues={recorderOptions} onChange={(v) => recorderOptions = v}></RecordOptions>
                    <div id="error-text" style={{ display: "none" }} className="text-danger my-2"></div>
                    <Button className="text-danger mx-4 my-2 w-50" title="Start Recording. Use the 'Stop sharing' button to stop recording." onClick={() => {
                        document.getElementById("error-text").style.display = "none";
                        document.getElementById("error-text").innerText = "";
                        initStream().then(startRecCountdown).catch(e => {
                            stopRec();
                            console.error(e);
                            document.getElementById("recorder-options").style.display = "";
                            document.getElementById("error-text").style.display = "";
                            document.getElementById("error-text").innerText = `There was an error with the recording: ${e.message}`;
                        })
                    }}>
                        <FontAwesomeIcon icon={faRecordIcon} size="2xl"></FontAwesomeIcon>
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default VideoRecorder;